.sun-editor {
    height: 100% !important;
}
.sun-editor .se-wrapper {
    height: calc(100% - 78px - 16px) !important; /* 100% - toolbar offsetHeight - bottom resizing bar offsetHeight */
}
.grid-column-headers {
    color: #246dff;
    font-family: 'WotfardMedium', sans-serif;
    font-size: 16px;
}
.MuiDataGrid-row.Mui-even {
    background-color: #FFF;
}
.MuiDataGrid-row.Mui-odd {
    background-color: #F3F3F4;
}

.MuiDataGrid-root.NoSelectAll .MuiDataGrid-columnHeaderCheckbox  {
    visibility: hidden !important;
}